* {
  margin: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,111131+25,000000+100 */
  /* background: #271129; */
  background: black;
  /* background: linear-gradient(0, #33aef5, #004581); */
}

body::before {
  content: '';
  z-index: -20;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(0, #3a0c28 25%, #000000);
}

.p5Canvas {
  position: fixed;
  top: 0;
  z-index: -10;
}

.wrapper {
  min-height: calc(100vh - var(--footerHeight));
  max-width: 675pt;
  margin: 0 auto 1rem;
  /* the bottom margin is the negative value of the footer's height */
}

.logo {
  margin: -50px 5% 35px;
  position: relative;
  top: 0;
  transition: top .5s;
}

.logo:hover {
  top: 1.5rem;
}

h1 {
  margin: 1em 1em 0;
  font-family: 'Turret Road', sans-serif;
  color: #eee;
  text-shadow: 0px 5px 5px black;
}

header {
  height: 68px;
  margin: 0 1rem 3vh;
  background: rgba(245, 245, 245, 0.8);
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: no-repeat center/100% 100% url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22880%22%20height%3D%2268%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22%23353535%22%20d%3D%22M20%200h840l20%2012H0L20%200z%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22%23DDD%22%20d%3D%22M18.214%2063L1.786%2017h876.428l-16.428%2046H18.214z%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22%23252525%22%20d%3D%22M1.786%2017L0%2012h880l-1.786%205H1.786zm860%2046L860%2068H20l-1.786-5h843.572z%22%2F%3E%3C%2Fsvg%3E')
}

header #social {
  display: flex;
  margin-right: 5%;
  align-self: flex-end;
  height: 40px;
  list-style-type: none;
  position: relative;
  top: 8px;
}

header #social li {
  margin-right: 10px;
}

header #social a {
  display: block;
}

header .ig {
  background: url('./assets/social/ig.svg');
}

header .gh {
  background: url('./assets/social/gh.svg');
}

header .yt {
  background: url('./assets/social/yt.svg');
}

header .in {
  background: url('./assets/social/in.svg');
}

header #social a div {
  height: 36px;
  width: 36px;
  opacity: 0;
}

header #social a:hover div {
  height: 36px;
  width: 36px;
  opacity: 1;
  filter: drop-shadow(-2px 0 0 #ff64d9) drop-shadow(2px 0 0 #00fff9);
  animation: glitch 2s infinite linear alternate;
}

@keyframes glitch {
  0% {
    clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
  }
  2% {
    clip-path: polygon(0 78%, 100% 78%, 100% 100%, 0 100%);
    transform: translate(-5px);
  }
  6% {
    clip-path: polygon(0 78%, 100% 78%, 100% 100%, 0 100%);
    transform: translate(5px);
  }
  8% {
    clip-path: polygon(0 78%, 100% 78%, 100% 100%, 0 100%);
    transform: translate(-5px);
  }
  9% {
    clip-path: polygon(0 78%, 100% 78%, 100% 100%, 0 100%);
    transform: translate(0);
  }
  10% {
    clip-path: polygon(0 54%, 100% 54%, 100% 44%, 0 44%);
    transform: translate(5px);
  }
  13% {
    clip-path: polygon(0 54%, 100% 54%, 100% 44%, 0 44%);
  }
  13.1% {
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    transform: translate(5px);
  }
  15% {
    clip-path: polygon(0 60%, 100% 60%, 100% 40%, 0 40%);
    transform: translate(5px);
  }
  20% {
    clip-path: polygon(0 60%, 100% 60%, 100% 40%, 0 40%);
    transform: translate(-5px);
  }
  20.1% {
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    transform: translate(5px);
  }
  25% {
    clip-path: polygon(0 85%, 100% 85%, 100% 40%, 0 40%);
    transform: translate(5px);
  }
  30% {
    clip-path: polygon(0 85%, 100% 85%, 100% 40%, 0 40%);
    transform: translate(-5px);
  }
  30.1% {
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
  35% {
    clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
    transform: translate(-5px);
  }
  40% {
    clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
    transform: translate(5px);
  }
  45% {
    clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
    transform: translate(-5px);
  }
  50% {
    clip-path: polygon(0 63%, 100% 63%, 100% 80%, 0 80%);
    transform: translate(0);
  }
  55% {
    clip-path: polygon(0 10%, 100% 10%, 100% 0, 0 0);
    transform: translate(5px);
  }
  60% {
    clip-path: polygon(0 10%, 100% 10%, 100% 0, 0 0);
  }
  60.1% {
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
  to {
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
}

header .menu {
  position: absolute;
  top: .3rem;
  padding: 0 2rem;
  transform: rotate(2deg);
  --padding-top: 13px;
}

header .menu a {
  float: left;
  display: block;
  height: 100%;
  /*background: #dedede;*/
  text-decoration: none;
  color: #090909;
  text-shadow: 0 2px white;
  font-family: 'Open Sans Condensed', sans-serif;
  padding: var(--padding-top) 15px 18px 15px;
}

header .menu a .activetab {
  padding-top: var(--padding-top);
  border-bottom: 3px solid #A6E22E;
}

header .menu a:hover {
  /*background: #ebebeb;*/
  border-bottom: 3px solid #33aef5;
}

footer {
  --footerHeight: 52pt;
  display: inline-block;
  height: var(--footerHeight);
  width: 100%;
  padding: 1rem 2rem;
  background-color: #f5f5f5cc;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: right;
  line-height: 25px;
}