.message {
  position: relative;
  margin: 0 2rem;
  background: center/100% 100% url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%222130px%22%20height=%22930px%22%3E%20%3Cpath%20stroke-width=%22.5%%22%20vector-effect=%22non-scaling-stroke%22%20stroke=%22rgb(19,%20168,%20156)%22%20fill=%22%231f1f1fcc%22%20d=%22M145.000,15.000%20L2115.000,15.000%20L2115.000,915.000%20L15.000,915.000%20L15.000,145.000%20L145.000,15.000%20Z%22/%3E%20%3C/svg%3E');
  padding: 2rem;
  opacity: .8;
  font-size: clamp(.5rem, 1vw, 1rem);
  font-weight: normal; /* render emojis on Windows */
}
.message::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: .3s infinite linear screen;
}

@keyframes screen {
  from {
      background: repeating-linear-gradient( 0, #00000000, #00000000 2px, #00000033 2px, #00000033 4px);
  }
  to {
      background: repeating-linear-gradient( 0, #00000033, #00000033 2px, #00000000 2px, #00000000 4px);
  }
}

.card{
  width: stretch;
  max-width: 35em;
  padding: 3em 5em 5em;
  position: relative;
  margin: 2rem;
  background-color: rgb(31 31 31 / 80%);
  color: white;
  display: inline-block;
  text-align: center;
  /* border: solid 5px #d1bbf9; */
  /* background-clip: padding-box; */
  --inner: #1f1f1f;
  --border: #d1bbf9;
  --shadow: #673ab7;
  box-shadow: 0  -10px 0   -5px var(--inner),
              0   10px 0   -5px var(--inner),
             -5px 0    0    0   var(--border),
              5px 0    0    0   var(--border),
              0  -5px  0    0   var(--border),
              0   5px  0    0   var(--border),
              0  -15px 0   -5px var(--border),
              0   15px 0   -5px var(--border),
             -5px 0    10px 5px var(--shadow),
              5px 0    10px 5px var(--shadow),
              0  -10px 10px 5px var(--shadow),
              0   10px 10px 5px var(--shadow);
  /* border-radius: 1em; */
}
.card:nth-of-type(even){
  float:right
}
.card>img, .card>h1{
  max-width: 100%;
  padding-bottom: 2em;
}
.card p{
  font-family:Raleway,sans-serif;
  font-size: 16pt;
  padding-bottom: 1em;
}
.card p span{
  display: flex;
  margin-top: 1em;
  text-align: left;
  font-family: Raleway, sans-serif;
  font-size:.8em;
}

.steam {
  position: absolute;
  bottom: 17px;
  right: 100px;
}
.steam svg {
  fill: #fff;
  transition:fill .2s ease-in-out;
}
.steam:hover svg {
  fill: #aaa;
}

.btn{
  position: absolute;
  bottom: 20px;
  right: 0;
  color:#fff;
  background-color:#0275d8;
  border:1px solid transparent;
  border-radius:.25rem 0 0 .25rem;
  padding:.5rem 1rem;
  text-decoration:none;
  font-family:-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
  transition:all .2s ease-in-out;
}
.btn:hover{
  background-color:#004581
}

.gallery {
  display: flex;
  position: relative;
  overflow: hidden;
  align-items: center;
}
/* .gallery::after {
  content: '';
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, #00000000 80%, #000000cc);
} */
.gallery img, .gallery-item {
  max-height: 100px;
  max-width: 150px;
}

.date {
  display: inline-block;
  position: absolute;
  right: 2em;
  font-family: 'Turret Road', sans-serif;
  transform: rotate(-90deg);
transform-origin: right;
}
.date::before {
  content: 'Y. ';
}
.rocket, .among-us {
  position: fixed;
  z-index: -15;
}
.among-us {
  top: 25vh;
  width: 30px;
  left: -30px;
  animation: 50s linear 2s infinite among-us;
}
@keyframes among-us {
  0% {
      left: -30px;
      transform: rotate(0);
  }
  80%, 100% {
      left: 100vw;
      transform: rotate(900deg);
  }
}
.rocket {
  top: 60vh;
  width: 60px;
  left: 100vw;
  transform: rotate(-150deg);
  animation: 50s linear 5s infinite rocket;
}
@keyframes rocket {
  0% {
      top: 60vh;
      left: 100vw;
  }
  60%, 100% {
      top: 10vh;
      left: -60px;
  }
}